import {
  useDisclosure,
  VStack,
  Center,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from '../../../components/card';
import Timeline from '../../../components/timeline';
import { timelineOptions } from '../../../components/timeline/util';
import { useAuthContext } from '../../../context/auth.context';
import { useSharedContext } from '../../../context/shared.context';
import TimelineAttachment from './timeline-attachments';
import TimelineBottomBar from './timeline/bottomBar';
import JoinAsParticipant from './timeline/joinAsParticipant';
import SendEmail from '../../../components/send-email-model';
import { doesHaveFullAccess } from '../../../utils/dashboard.utils';

const TeamCollaboration = ({
  activity,
  isOverviewOpen,
  onFormSelection,
  onOpenParticipantView,
  participants = [],
  reloadParticipant,
  loadSuperAdmins = [],
  isMobileView
}) => {
  const {
    state: { authData },
  } = useAuthContext();
  const chakraTheme = useTheme();
  const {
    state: {
      timelineFilter,
      isReportingManagerLead,
      isReportingManagerOwner,
      isShowLoader,
      random,
      selectedQueue
    },
  } = useSharedContext();
const [fileAccessTyp, setfileAccessTyp] = useState('')
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isReply,
    onClose: onCloseReply,
    onOpen: onOpenReply,
  } = useDisclosure();
  const {
    isOpen: isSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();
  const [replyData, setReplyData] = useState(null);

  let doesHaveAccess =
    !!isReportingManagerOwner ||
    !!isReportingManagerLead ||
    participants.find(item => {
      return item.asset_id === authData.asset_id;
    });
  if (!doesHaveAccess) {
    doesHaveAccess = doesHaveFullAccess(authData.asset_id, doesHaveAccess);
  }

  let isSuperAdminCheck =
    !!loadSuperAdmins.length &&
    loadSuperAdmins?.find(
      item => item.asset_type_id === authData.asset_type_id
    );
  let isUserHaveAccess =
    !!loadSuperAdmins.length &&
    loadSuperAdmins.find(item => item.asset_id === authData.asset_id);

  const timelineReplyData = (data = null) => {
    setReplyData(data);
    if (!!data) {
      onOpenReply();
    } else {
      onCloseReply();
    }
  };

  const onOpenAttachment=(type)=>{
  setfileAccessTyp(type)
  onOpen();
  }

  return (
    <Card
      w="100%"
      h="100%"
      position="relative"
      className={`team-collaborations-container`}
    >
      {isOpen ? (
        <TimelineAttachment
          isOpen={isOpen}
          onClose={onClose}
          activity={activity}
          fileAccessTyp={fileAccessTyp}
        />
      ) : null}
      <VStack w="100%">
        <VStack
          className={`team-collaborations-timeline-container`}
          w="100%"
          style={{
            transformOrigin: 'bottom',
            transition: 'transform 0.26s ease',
          }}
          maxH={isOverviewOpen ? 'calc(100vh - 370px)' : isMobileView ? 'calc(100vh - 170px)': 'calc(100vh - 270px)'}
          minH={isOverviewOpen ? 'calc(100vh - 370px)' : isMobileView ? 'calc(100vh - 170px)': 'calc(100vh - 270px)'}
          flex={1}
        >
          <Timeline
            onFormSelection={onFormSelection}
            activity={activity}
            filter={timelineFilter}
            timelineReplyData={timelineReplyData}
            isUserHaveAccess={isUserHaveAccess}
          />
        </VStack>
        { Number(activity.activity_type_flag_disable_bottom_bar) !== 1 || !!isSuperAdminCheck ?
        (!!authData.asset_flag_super_admin ||
        !!doesHaveAccess ||
        !!isSuperAdminCheck ||
        !!isUserHaveAccess ||
        (!!selectedQueue && selectedQueue.queue_flag_join_lead === 3)  ? (
          <TimelineBottomBar
            activity={activity}
            openAttachment={onOpenAttachment}
            onOpenParticipantView={onOpenParticipantView}
            isReplyOn={isReply}
            timelineReplyData={timelineReplyData}
            replyData={replyData}
            onOpenSendEmail={onOpenSendEmail}
            isMobileView={isMobileView}
          />
        ) : !!participants && participants.length ? (
          <JoinAsParticipant
            reloadParticipant={reloadParticipant}
            activity={activity}
          />
        ) : null) 
        :null}
        <SendEmail
          isOpen={isSendEmail}
          onClose={onCloseSendEmail}
          activity={activity}
        />
      </VStack>
    </Card>
  );
};

export default TeamCollaboration;
