import React, { useState } from 'react'
import {
	Box,
	VStack,
	Text,
	Divider,
	Avatar,
	Flex,
	Spacer,
	Icon,
	IconButton,
	HStack,
	Center,
	Spinner,
} from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import {
	getUTCtoIST,
	timelineDateFormat,
	getCurrentDateUTC,
} from '../../../../../../utils/date.utils'
import {
	convertPound,
	convertUnicode,
} from '../../../../../../utils/linkInText.utils'
const ReadMoreText = ({ text, maxLength }) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const toggleReadMore = () => {
		setIsExpanded(!isExpanded)
	}
	const displayText = isExpanded ? text : text?.slice(0, maxLength)

	return (
		<div>
			<Text
				whiteSpace='pre-line'
				fontSize='sm'
				textAlign='left'
				mt={4}
				dangerouslySetInnerHTML={{
					__html: convertPound(convertUnicode(unescape(displayText))),
				}}
			/>
			{text?.length > maxLength && (
				<button
					alignSelf='flex-start'
					cursor='pointer'
					fontSize='md'
					display='inline'
					style={{ color: '#4daf89', fontWeight: 'bold' }}
					ml={1}
					onClick={toggleReadMore}
				>
					{isExpanded ? 'read less' : 'read more'}
				</button>
			)}
		</div>
	)
}
const EmailConversation = ({
	emailThreads,
	setListView,
	setThreadData,
	isLoading,
}) => {
	if (isLoading) {
		return (
			<Center p='10'>
				<Spinner size='lg' color='brand.900' />
			</Center>
		)
	}
	emailThreads.forEach(obj => {
		const sortDateTime =
			obj.email_flag_received === 0
				? obj.email_sent_datetime
				: obj.email_received_datetime
		obj.sortDateTime = sortDateTime
	})
	emailThreads.sort(
		(a, b) => new Date(a.sortDateTime) - new Date(b.sortDateTime)
	)
	return (
		<Box w='100%'>
			{/* <Flex>
				<IconButton
					bg='white'
					borderRadius='md'
					boxShadow='md'
					aria-label='Refresh'
					cursor='pointer'
					size='md'
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					onClick={() => {
						setListView(true)
						setThreadData([])
					}}
					icon={<Icon as={BiArrowBack} w={6} h={6} />}
				/>
			</Flex> */}
			{/* <VStack
				align='start'
				spacing={4}
				width='100%'
				h='380px'
				overflowY='scroll'
			>
				{emailThreads?.map((thread, index) => (
					<Box key={index} width='100%' borderRadius='md' p={4} boxShadow='md'>
						<Flex align='center'>
							<Avatar
								size='sm'
								color={'white'}
								bg={'brand.800'}
								name={
									thread.email_from && JSON.parse(thread.email_from)[0]?.name
								}
								src={thread.email_from[0]?.avatar}
							/>
							<Box ml={3} textAlign='justify'>
								<Text fontWeight='bold'>
									{thread.email_from && JSON.parse(thread.email_from)[0]?.name}
								</Text>
								<Text fontSize='sm' color='gray.500'>{`to ${
									thread.email_to && JSON.parse(thread.email_to)[0]?.name
								}`}</Text>
							</Box>
							<Spacer />
							<Text fontSize='sm' color='gray.500' textAlign='right'>
								{timelineDateFormat(
									getUTCtoIST(
										thread.email_flag_received === 1
											? thread.email_received_datetime
											: thread.email_sent_datetime
									)
								)}
							</Text>
						</Flex>

						<Text textAlign='left' mt={2}>
							{thread.email_subject}
						</Text>

						<Text
							whiteSpace='pre-line'
							fontSize='sm'
							textAlign='left'
							mt={4}
							dangerouslySetInnerHTML={{
								__html: convertPound(
									convertUnicode(unescape(thread.email_body))
								),
							}}
						/>
						<Divider my={2} />
					</Box>
				))}
			</VStack> */}
			<Box h='430px' overflowY='scroll'>
				{emailThreads.length > 0 ? (
					emailThreads?.map((thread, index) => (
						<Flex
							className={`timeline-integration-update`}
							justifyContent='flex-start'
							alignItems='flex-start'
							my={4}
							flexDirection={
								thread.email_flag_received === 0 ? 'row-reverse' : 'row'
							}
							mr={2}
						>
							<VStack
								alignItems='center'
								className={`timeline-integration-update-profile`}
							>
								<Avatar
									name={
										thread.email_from && JSON.parse(thread.email_from)[0]?.name
									}
									size='md'
									bg={
										thread.email_flag_received === 0 ? 'brand.800' : '#b519ed'
									}
								/>
								<Text fontSize='sm' mr={2}>
									{' '}
									{thread.email_from && JSON.parse(thread.email_from)[0]?.name}
								</Text>
							</VStack>
							<VStack
								className={`timeline-integration`}
								justifyContent='space-around'
								alignItems='center'
								bg='secondary'
								borderRadius='lg'
								minh='170px'
								maxh='250px'
								wordBreak='break-word'
								minW='200px'
								maxW='300px'
								mx={2}
								px={4}
								py={2}
								position='relative'
							>
								<HStack alignItems='flex-start'>
									{/* <Tooltip
								label='integration udpates'
								fontSize='sm'
								bg='secondary'
								color='black.700'
							>
								<Box borderRadius='md' p={2} bg='white' cursor='pointer'>
									<Icon as={GrIntegration} w={6} h={6} color='brand.800' />
								</Box>
							</Tooltip> */}

									<Box textAlign='left' fontSize='14px'>
										<Text fontWeight='bold'>
											{thread.email_from &&
												JSON.parse(thread.email_from)[0]?.name}
										</Text>
										<Text fontSize='sm' color='gray.500'>{`to ${
											thread.email_to && JSON.parse(thread.email_to)[0]?.name
										}`}</Text>
										<Text textAlign='left' fontSize='14px' mb='10px' mt='5px'>
											{thread.email_subject}
										</Text>
										<Divider />
										{thread.email_body?.length > 60 ? (
											<ReadMoreText
												text={thread.email_body && thread.email_body}
												maxLength={60}
											/>
										) : (
											<Text
												whiteSpace='pre-line'
												fontSize='sm'
												textAlign='left'
												mt={4}
												dangerouslySetInnerHTML={{
													__html: convertPound(
														convertUnicode(unescape(thread.email_body))
													),
												}}
											/>
										)}
									</Box>
								</HStack>

								<Text fontSize='10px' alignSelf='flex-start' mb={0}>
									{timelineDateFormat(
										getCurrentDateUTC(
											thread.email_flag_received === 1
												? thread.email_received_datetime
												: thread.email_sent_datetime
										)
									)}
								</Text>
							</VStack>
						</Flex>
					))
				) : (
					<Center p='10'>
						<Text>No data found!</Text>
					</Center>
				)}
			</Box>
		</Box>
	)
}

export default EmailConversation
