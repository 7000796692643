import React, { useState, useEffect } from 'react';
import {
  Divider,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import StatusListContainer from './status-listing';
import Card from '../../../components/card';
import DelegateFormPopup from './form-delegation';

const DataManagement = ({
  isOverviewOpen,
  participants,
  onFormSelection,
  isFormEnable,
  activity,
  onDelegateFormClose,
}) => {
  const [selectedForm, setSelectedForm] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openDelegateFormPopup = form => {
    setSelectedForm(form);
    onOpen();
  };

  const closeDelegateFormPopup = () => {
    onDelegateFormClose();
    onClose();
  };

  return (
    <Card
      className={`data-management-card`}
      maxH={isOverviewOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 180px)'}
      minH={isOverviewOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 180px)'}
      h="100%"
      w="100%"
      px={4}
      overflowY="scroll"
    >
      <StatusListContainer
        participants={participants}
        onFormSelection={onFormSelection}
        isFormEnable={isFormEnable}
        openDelegateFormPopup={openDelegateFormPopup}
      />
    </Card>
  );
};

export default DataManagement;
