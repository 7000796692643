/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import StatusListingItem from './listing-item'
import { useSharedContext } from '../../../../context/shared.context'
import { useLoadActivityStatusList } from '../../../../hooks/form.hooks'
import {
  Skeleton,
  VStack,
  HStack,
  Center,
  Icon,
  Text,
  Divider,
  Collapse,
  Spinner,
  useTheme,
  useDisclosure,
} from '@chakra-ui/react'
import { useAuthContext } from '../../../../context/auth.context'
import { MdArrowDropDown } from 'react-icons/md'
import { toSnakeCase } from '../../../../utils/common.util'
import { doesHaveFullAccess } from '../../../../utils/dashboard.utils'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_WORKSPACE_PANEL } from './../../../repository/repo.utils'

const StatusListContainer = ({
  participants,
  onFormSelection,
  isFormEnable,
  openDelegateFormPopup,
}) => {
  const {
    state: { activity, isReportingManagerOwner, isReportingManagerLead },
  } = useSharedContext()
  const {
    state: {
      authData: { asset_id },
    },
  } = useAuthContext()
  const { isStatusOpen, onToggle } = useDisclosure()
  const [selectedStatusTypeId, setSelectedStatusTypeId] = useState('')

  const { mutate: loadStatusList, isLoading } = useLoadActivityStatusList()
  const [groupStatuses, setgroupStatuses] = useState([])
  const [isOwner, setisOwner] = useState(false)
  const chakraTheme = useTheme()

  const loadStatuses = activity => {
    if (participants && participants.length) {
      let owner =
        !!isReportingManagerOwner ||
        !!isReportingManagerLead ||
        participants.find(p => p.asset_id === asset_id && p.asset_flag_is_owner)

      if (!owner) {
        owner = doesHaveFullAccess(asset_id, owner)
      }
      setisOwner(!!owner)
    }
    loadStatusList(
      {
        activity_type_category_id: activity.activity_type_category_id,
        activity_type_id: activity.activity_type_id,
      },
      {
        onSuccess: async data => {
          setgroupStatuses(data)
        },
        onError: async err => {
          console.log('error occurred while loading status', err)
        },
      }
    )
  }

  useEffect(() => {
    if (activity) {
      loadStatuses(activity)
    }
  }, [])

  useEffect(() => {
    if (activity) {
      loadStatuses(activity)
      setSelectedStatusTypeId(activity.activity_status_type_id)
    }
  }, [])

  if (!!isLoading) {
    return (
      <Center p={2} my={1}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={chakraTheme.colors.secondary}
          color={chakraTheme.colors.brand[800]}
          size="xl"
        />
      </Center>
    )
  }

  // TODO: make the statuses to be collapsible.
  return (
    <HStack w={'full'} alignItems={'flex-start'}>
      {(Object.keys(groupStatuses) || []).map(groupId => {
        let status = groupStatuses[groupId][0]
        if (!groupStatuses[groupId].length) {
          return null
        }
        return (
          <VStack w={'full'} h={'full'} key={groupId}>
            <HStack
              key={groupId}
              w={'full'}
              className={`status-type-${toSnakeCase(
                status.activity_status_type_name
              )}`}
              justifyContent="space-between"
              borderRadius="md"
              boxShadow="md"
              px={3}
              py={1}
            >
              <Text
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_WORKSPACE_PANEL,
                    action: 'Status Life Cycle',
                    label: 'Select Status',
                  })
                  setSelectedStatusTypeId(status.activity_status_type_id)
                }}
                fontWeight="500"
                color="brand.800"
                fontSize="md"
                flex={1}
                className={`status-type-text-${toSnakeCase(
                  status.activity_status_type_name
                )}`}
              >
                {status.activity_status_type_name}
              </Text>
              {/* <Icon
                as={MdArrowDropDown}
                w={6}
                h={6}
                onClick={() => {
                  setSelectedStatusTypeId(status.activity_status_type_id);
                }}
              /> */}
            </HStack>
            <Collapse
              style={{ width: '100%' }}
              className={`status-listing-activity-status-collapse`}
              in={true}
            >
              {(groupStatuses[groupId] || []).map((status, i) => {
                return (
                  <Fragment key={status.activity_status_id}>
                    <StatusListingItem
                      activity={{ ...activity, isOwner }}
                      status={status}
                    />
                  </Fragment>
                )
              })}
            </Collapse>
          </VStack>
        )
      })}
    </HStack>
  )
}

export default StatusListContainer
