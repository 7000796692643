/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	Icon,
	IconButton,
	List,
	ListItem,
	Text,
	Tooltip,
	VStack,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import {
	useLoadDomainList,
	useLoadEmailThreadList,
} from '../../../../../hooks/activity.hooks'
import { sharedConst } from '../../../../../utils/action.constant'
import {
	getUTCtoIST,
	getCurrentDateUTC,
	timelineDateFormat,
} from '../../../../../utils/date.utils'

import { useSharedContext } from './../../../../../context/shared.context'

const DomainList = ({
	setThreadId,
	setDomainThreadList,
	setIsclick,
	activityId,
}) => {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { dispatch } = useSharedContext()

	const [domainList, setDomainList] = useState([])
	const { mutate: loadDomainList } = useLoadDomainList()
	const { mutate: loadThreadList } = useLoadEmailThreadList()
	// const loadDomains = useCallback(() => {
	// 	loadDomainList(
	// 		{ asset_id: 41535 }, //loggedInuserId
	// 		{
	// 			onSuccess: async data => {
	// 				setDomainList(data?.list)
	// 			},
	// 		}
	// 	)
	// })
	// const loadDomains = useCallback(async (start = 0, previousData = []) => {
	// 	const response = await loadDomainList(
	// 		{
	// 			asset_id: authData.asset_id, //41535
	// 			limit_value: 100,
	// 			start_from: start,
	// 		},
	// 		{
	// 			onSuccess: async data => {
	// 				// Concatenate the new data with the previous data
	// 				const updatedData = previousData.concat(data?.list || [])
	// 				setDomainList(updatedData)
	// 				if (data?.isMore) {
	// 					// If there are more records, recursively call loadDomains with the next page_start
	// 					await loadDomains(start + 100, updatedData)
	// 				}
	// 			},
	// 		}
	// 	)
	// }, [])
	const loadDomains = useCallback(async (start = 0, previousData = []) => {
		//setIsThreadLoading(true)
		const response = await loadThreadList(
			{
				account_activity_id: activityId,
				limit_value: 100,
				start_from: start,
			},
			{
				onSuccess: async data => {
					//setIsThreadLoading(false)

					// Concatenate the new data with the previous data
					const updatedData = previousData.concat(data?.list || [])
					setDomainList(updatedData)
					if (data?.isMore) {
						// If there are more records, recursively call loadDomains with the next page_start
						await loadDomains(start + 100, updatedData)
					}
				},
			}
		)
	})
	useEffect(() => {
		loadDomains()
	}, [activityId])

	return (
		<VStack h='full' w='40%'>
			<Box
				bg='white'
				w='100%'
				mb={4}
				py={2}
				textAlign='center'
				borderRight='solid 1px #70707047'
			>
				{domainList && domainList?.length > 0 ? (
					<List
						px={5}
						w='full'
						spacing={3}
						h='490px'
						overflowY='scroll'
						overflowX='hidden'
					>
						{(domainList || []).map(u => {
							const {
								email_subject,
								email_received_datetime,
								email_from,
								thread_id,
								email_flag_received,
								email_sent_datetime,
							} = u

							return (
								<>
									<ListItem>
										<Flex
											spacing={2}
											cursor={'pointer'}
											alignItems='flext-start'
											onClick={() => {
												setThreadId(thread_id)
												setDomainThreadList([])
												setIsclick()
											}}
										>
											{/* <Center position='relative' alignSelf='center'>
												<Avatar
													name={fName}
													color={'white'}
													bg={'brand.800'}
													size='md'
												/>
											</Center> */}
											<VStack ml={2} flex={1} alignItems='flex-start'>
												<Text
													color='brand.800'
													fontSize='14px'
													//textTransform='capitalize'
												>
													{email_subject}
												</Text>
												<Text
													//color='brand.800'
													fontSize='14px'
													textTransform='capitalize'
												>
													{email_from && JSON.parse(email_from)[0]?.name}
												</Text>
												<Text>
													{timelineDateFormat(
														getCurrentDateUTC(
															email_received_datetime === '0000-00-00 00:00:00'
																? email_sent_datetime
																: email_received_datetime
														)
													)}
												</Text>
												{/* <Text
																color='brand.800'
																fontSize='12px'
																textAlign='left'
															>
																{`+${cCode} ${pCode}`} |{' '}
																{operating_asset_email_id}
															</Text>
															<Text
																color='brand.800'
																fontSize='12px'
																textAlign='left'
															>
																{account_name}
															</Text> */}
											</VStack>
										</Flex>
									</ListItem>
									<Divider border='1px' borderColor='secondary' />
								</>
							)
						})}
					</List>
				) : (
					<Center p='10'>
						<Text>No data found!</Text>
					</Center>
				)}
			</Box>
		</VStack>
	)
}

export default DomainList
