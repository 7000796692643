/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse, HStack, Icon, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, Fragment } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { toSnakeCase } from '../../../../../utils/common.util'
import { sectionDetailedTrack } from '../../../../repository/repo.utils'
import SubStatusChips from '../subStatus'
import { GA_CATEGORY_WORKSPACE_PANEL } from './../../../../repository/repo.utils'

const StatusListingItem = ({ status, activity }) => {
  const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    if (
      !(
        status.activity_status_id !== activity.parent_status_id &&
        status.activity_status_id !== activity.activity_status_id
      )
    ) {
      //onToggle();
    }
  }, [])

  let isCurrentStatus = !(
    status.activity_status_id !== activity.parent_status_id &&
    status.activity_status_id !== activity.activity_status_id
  )

  return (
    <Fragment key={status.activity_status_id}>
      <HStack
        className={`status-${toSnakeCase(status.activity_status_name)} ${
          isOpen ? ' opened' : ''
        }`}
        justifyContent="space-between"
        borderRadius="md"
        boxShadow="sm"
        w="full"
        mb={2}
        p={3}
        py={2}
        bg={isCurrentStatus ? localStorage.getItem('color') : 'secondary'}
        onClick={() => {
          sectionDetailedTrack({
            category: GA_CATEGORY_WORKSPACE_PANEL,
            action: 'Status Life Cycle',
            label: 'Expand',
          })
          onToggle()
        }}
      >
        {!(
          status.activity_status_id !== activity.parent_status_id &&
          status.activity_status_id !== activity.activity_status_id
        ) ? (
          <Text fontSize="sm" color="white" cursor="pointer">
            {status.activity_status_name}
          </Text>
        ) : (
          <Text fontSize="sm" cursor="pointer">
            {status.activity_status_name}
          </Text>
        )}
        <Icon
          as={isOpen ? BiChevronUp : BiChevronDown}
          w={5}
          h={5}
          cursor="pointer"
        />
      </HStack>
      <Collapse
        in={isOpen}
        className={`status-listing-item-sub-status${isOpen ? ' expand' : ''}`}
      >
        <SubStatusChips activity={activity} status={status} />
      </Collapse>
    </Fragment>
  )
}

export default StatusListingItem
