/* eslint-disable react-hooks/exhaustive-deps */
import { HStack, VStack, Skeleton, Box, Icon, Text } from '@chakra-ui/react';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { useLoadSubStatusList } from '../../../../hooks/form.hooks';
import { toSnakeCase } from '../../../../utils/common.util';
const SubStatusChips = ({ status, activity }) => {
  const { mutate: mutateSubStatus, data, isLoading } = useLoadSubStatusList();

  useEffect(() => {
    mutateSubStatus({
      activity_id: activity.activity_id,
      activity_status_id: status.activity_status_id,
    });
  }, []);

  if (!!isLoading) {
    return (
      <VStack w="full" spacing={2} py={2} px={3} overflowX="scroll">
        {[1, 2, 3, 4].map(i => (
          <Skeleton key={i} w="full" h="30px" borderRadius="full" />
        ))}
      </VStack>
    );
  }

  if (!data || (data && !data.length)) {
    return null;
  }
  return (
    <VStack w="full" borderWidth={0} borderRadius={'md'} spacing={2} p={3}>
      {(sortBy(data, ['trigger_flag']) || []).reverse().map(d => {
        return (
          <HStack
            w="full"
            borderRadius="md"
            boxShadow="md"
            key={d.activity_status_id}
            variant="outline"
            px={3}
            py={1}
            bg={d.trigger_flag ? 'white' : 'secondary'}
          >
            {d.trigger_flag ? (
              <Icon as={BsCheckCircle} w={5} h={5} color="brand.800" />
            ) : null}
            <Text
              className={`sub-status-text-${toSnakeCase(
                d.activity_status_name
              )}`}
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {d.activity_status_name}
            </Text>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default SubStatusChips;
