/* eslint-disable react-hooks/exhaustive-deps */
import {
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Button,
	Text,
	Divider,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { useLanguageContext } from '../../../../context/locale.context'
import {
	useLoadEmailThreadList,
	useLoadEmailThreadmaping,
} from '../../../../hooks/activity.hooks'
// import { dataLayerTagManager } from '../../../repository/repo.utils'
import DomainList from './domian-list'
import TheardsList from './thread-list'
import EmailConversation from './thread-list/mail-conversations'
const EmailView = ({
	activity,
	activityTypeDetails,
	isOpen,
	onClose,
	showExternal = true,
	addExternalUsers = null,
	inviteDelegate = false,
	addDelegate = null,
}) => {
	const [isReload, setisReload] = useState(false)
	const [searchQuery, setsearchQuery] = useState('')
	const [activeParticipants, setActiveParticipants] = useState([])
	const [threadId, setThreadId] = useState()
	const [domainThreadList, setDomainThreadList] = useState([])
	const [isThreadLoading, setIsThreadLoading] = useState(true)
	const [threadData, setThreadData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const {
		state: { locale },
	} = useLanguageContext()

	const onDebounceSearch = useCallback(
		debounce(e => {
			setsearchQuery(e.target.value)
		}, 400)
	)
	const { mutate: loadThreadList } = useLoadEmailThreadList()
	const { mutate: loadThreadmapList } = useLoadEmailThreadmaping()

	const loadDomains = useCallback(async (start = 0, previousData = []) => {
		setIsThreadLoading(true)
		const response = await loadThreadList(
			{
				account_activity_id: activity?.activity_id,
				limit_value: 100,
				start_from: start,
			},
			{
				onSuccess: async data => {
					setIsThreadLoading(false)
					// Concatenate the new data with the previous data
					const updatedData = previousData.concat(data?.list || [])
					setDomainThreadList(updatedData)
					if (data?.isMore) {
						// If there are more records, recursively call loadDomains with the next page_start
						await loadDomains(start + 100, updatedData)
					}
				},
			}
		)
	})
	const ThreadmapList = useCallback(
		async (threadId, start = 0, previousData = []) => {
			setIsLoading(true)
			const response = await loadThreadmapList(
				{
					thread_id: threadId, //threadId
					limit_value: 100,
					start_from: start,
					account_activity_id: activity?.activity_id,
				},
				{
					onSuccess: async data => {
						setIsLoading(false)
						// Concatenate the new data with the previous data
						const updatedData = previousData.concat(data?.list || [])
						setThreadData(updatedData)
						if (data?.isMore) {
							// If there are more records, recursively call loadDomains with the next page_start
							await ThreadmapList(threadId, start + 100, updatedData)
						}
					},
				}
			)
		},
		[]
	)
	useEffect(() => {
		//if (threadId) {
		ThreadmapList(threadId)
		//}
	}, [threadId])
	// useEffect(() => {
	// 	loadDomains()
	// }, [activity])
	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent borderRadius='md' bg='white' minW='70vw' m='auto' h='550px'>
				<ModalCloseButton zIndex='100' />
				<ModalBody p={0} w='100%' position='relative'>
					<HStack
						position='absolute'
						top='10px'
						//minW='calc(70vw - 100px)'
						w='99%'
						ml={5}
					>
						<Text color={'black'} fontSize='16' textAlign='center'>
							Email Communications
						</Text>
					</HStack>
					<Divider border='2px' mt='40px' borderColor='secondary' />

					<HStack h='full' alignItems='flex-start'>
						<DomainList
							setThreadId={setThreadId}
							setDomainThreadList={setDomainThreadList}
							setIsclick={loadDomains}
							activityId={activity?.activity_id}
						/>
						{/* <TheardsList
							activity={activity}
							domainThreadList={domainThreadList}
							isThreadLoading={isThreadLoading}
							activeAccountActivityId={activeAccountActivityId}
						/> */}
						<EmailConversation
							emailThreads={threadData}
							//setListView={setListView}
							setThreadData={setThreadData}
							isLoading={isLoading}
						/>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default EmailView
